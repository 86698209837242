<template>
	<div class="page">
		<el-row class="tool-bar">
			<el-col :span="12">
				<search-tool v-model="search" @input="$utils('search', getMestaKoef)"/>
			</el-col>
			<el-col :span="12" align="right">
				<el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/mesta_koef/create')" v-if="$utils('roleUser', 'Mesta', 'create')">{{ $t('global.novo') }}</el-button>
			</el-col>
		</el-row>
		<el-row>
			<!--Table-->
			<el-table v-if="mestaKoef.length"
					:data="mestaKoef"
					ref="table"
					:default-sort="sortBy"
					@sort-change="sort => $utils('changeSort', sort, getMestaKoef)"
					v-loading="gridLoad"
					border
					stripe>
				<el-table-column
						align="left"
						prop="naziv"
						:label="$t('mesta.naziv')"
						sortable="custom">
				</el-table-column>
				<el-table-column
						width="120">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Mesta', 'edit')">
							<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/mesta_koef/' + scope.row.id_segmenta + '')" size="small"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Mesta', 'delete')">
							<el-button type="danger" icon="fas fa-trash" @click="$delete('koefLinijeMesta', scope.row.id_segmenta)" size="small"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
		</el-row>
		<br>
		<el-row>
			<pagination v-model="pagination" @input="getMestaKoef" ref="pagination"/>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'mesta-koef-list',
	data() {
		return {
			mestaKoef: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('mesta.mestaKoef')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getMestaKoef()]);
	},
	methods: {
		getMestaKoef() {
			this.gridLoad = true;
			return this.$get('koefLinijeMesta', this.$utils('getParams')).then(data => {
				this.mestaKoef = data;
				this.gridLoad = false;
			});
		}
	}
};
</script>
